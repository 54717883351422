import { Grid } from "@material-ui/core"
import React from "react"
import { PageTitles } from "../../shared/constants"
import PageTitleDescription from "../Common/PageTitleDescription"
import AllPosts from "./AllPosts"

export const Blog = () => (
  <Grid container direction={"column"}>
    <PageTitleDescription
      title={PageTitles.blog.title}
      description={PageTitles.blog.description}
    />
    <AllPosts />
  </Grid>
)
