import { Grid, Typography, Button, makeStyles } from "@material-ui/core"
import React from "react"
import ebook from "../../imgs/offers/beautiful-apps-faster.jpeg"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.surface,
    top: theme.spacing(10),
    padding: theme.spacing(2),
    position: "sticky",
  },
  imageContainer: {
    position: "relative !important",
    width: "100% !important",
    height: "100% !important",
    overflow: "hidden",
  },
  image: {
    width: "100% !important",
    height: "100% !important",
  },
  offerTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  offerButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  offerLink: {
    textDecoration: "none",
  },
}))

export const ShowExclusive = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction={"column"}
      justify={"space-between"}
      className={classes.root}
    >
      <Grid item className={classes.imageContainer}>
        <a
          href={
            "https://mailchi.mp/f5781489f94f/bonsaiilabs-build-beautiful-react-apps-faster"
          }
          target={"_blank"}
          rel="noopener noreferrer"
          className={classes.offerLink}
        >
          <img src={ebook} alt={"ebook"} className={classes.image} loading={"lazy"}/>
        </a>
      </Grid>
      <Grid item className={classes.offerTitle}>
        <Typography variant={"h6"} gutterBottom>
          Developer's Guide to create design faster
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"subtitle2"} gutterBottom>
          A FREE E-book
        </Typography>
      </Grid>
      <Grid item className={classes.offerButton}>
        <a
          href={
            "https://mailchi.mp/f5781489f94f/bonsaiilabs-build-beautiful-react-apps-faster"
          }
          target={"_blank"}
          rel="noopener noreferrer"
          className={classes.offerLink}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            aria-label={"DOWNLOAD NOW"}
          >
            DOWNLOAD NOW
          </Button>
        </a>
      </Grid>
    </Grid>
  )
}
