import React from "react"
import Layout from "../components/layout"
import { Blog } from "../components/Posts/Blog"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"

export default () => {
  const seo = seoData.blogPage
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.metaDescription}
        pathname={seo.pathname}
      />
      <Layout selectedTabValue={5}>
        <Blog />
      </Layout>
    </>
  )
}
