import React from "react"
import { Grid } from "@material-ui/core"
import { ShowExclusive } from "./ShowExclusive"
import ShowAllPosts from "./ShowAllPosts"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(query)
  return (
    <Grid container justify={"space-between"}>
      <Grid item xs={12} md={7}>
        <ShowAllPosts alignPosts={"center"} edges={data.allMdx.edges}/>
      </Grid>
      <Grid item xs={12} md={3}>
        <ShowExclusive/>
      </Grid>
    </Grid>
  )
}

export const query = graphql`
  query {
    allMdx(filter: {fileAbsolutePath: {regex: "/(posts|articles)/"}}, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

